const REMIND_ATTENDANCE_NORMAL =
  "<br>Thầy/Cô vui lòng hỗ trợ Học viên ôn tập nội dung bài cũ, điều chỉnh tốc độ giảng dạy và\
   quan tâm Học viên hơn trong buổi học này nhé!";

const REMIND_HOMEWORK_NORMAL = "<br>Thầy/Cô vui lòng tìm hiểu lý do và khích lệ tinh thần Học viên trong buổi học tới nha.<br>";
const REMIND_HOMEWORK_BAD_SCORE = "<br>Thầy/Cô vui lòng hỗ trợ học viên cải thiện chất lượng bài làm trong buổi học tới nhé.<br>";
const REMIND_HOMEWORK_LEAVE_TEST = "<br>Thầy/Cô giúp học viên một số tài liệu tự ôn tập kiểm tra hoặc nhắc bạn học kèm với Gia sư/ kết nối với cố vấn học tập để được hỗ trợ phương pháp ôn tập nhé!.<br>";

export const countLeaveHomeworkStudent = (value, students , type) => {
  let message_attend = `<b>1</b>. Có ${value} học viên đã nghỉ buổi trước:<br>`;
  let message_homework = `<br><b>2</b>. Có ${value} học viên đi học buổi trước nhưng chưa làm BTVN:<br>`;
  let message_badscore = `<br><b>3</b>. Dưới đây là danh sách Học viên có điểm BTVN của buổi học trước chưa cao:<br>`;
  let message_attendances_test = `<br><b>1</b>. Có nhiều học viên nghỉ học nhiều từ đầu khóa đến hiện tại:<br>`;

  let message = '';
  switch (type) {
    case 'attend':
      message = message_attend
      break;
    case 'homework':
      message = message_homework
      break;
    case 'badscore':
      message = message_badscore
      break;
    case 'leaveTest':
      message = message_attendances_test
      break;
    default:
      break;
  }
  students?.forEach((student) => {
    message += `&nbsp;&nbsp;&nbsp;<b>.</b> ${student}<br>`;
  });
  let remind = '';
  switch (type) {
    case 'attend':
      remind = REMIND_ATTENDANCE_NORMAL
      break;
    case 'homework':
      remind = REMIND_HOMEWORK_NORMAL
      break;
    case 'leaveTest':
      remind = REMIND_HOMEWORK_LEAVE_TEST
      break;
    default:
      break;
  }
  return message + remind;
};

export const countStudentDontHomeWork = (value, students) => {
    let message = `<br><b>2</b>. Có ${value} học viên đi học buổi trước nhưng chưa hoàn thành BTVN:<br>`;
    students?.forEach((student) => {
      message += `&nbsp;&nbsp;&nbsp;<b>.</b> ${student}<br>`;
    });
    return message + REMIND_HOMEWORK_NORMAL;
};   

export const REMIND_ATTENDANCE_TEST =
  "<b>1</b>. Có nhiều học viên nghỉ học từ đầu khóa đến hiện tại. Thầy/Cô giúp học viên một số tài liệu\
 tự ôn tập kiểm tra hoặc nhắc bạn học kèm với Gia sư/ kết nối với cố vấn học tập để được hỗ trợ phương pháp ôn tập nhé!";

export const REMIND_HOMEWORK_TEST =
  "<b>2</b>. Dưới đây là danh sách học viên có điểm BTVN nhiều lần thấp từ đầu khóa đến hiện tại. Thầy/Cô giúp Học viên một số tài liệu tự ôn tập kiểm tra nhé!";

export const REMIND_ATTENDANCE_LAST_OTHER =
  "<b>1</b>. Đây là danh sách Học viên nghỉ học trong tháng này. Thầy/Cô giúp Học viên một số tài liệu tự học hoặc nhắc bạn học bù cùng Gia sư nhé!";
export const REMIND_ATTENDANCE_LAST_ECOACH = "<b>1</b>. Đây là danh sách Học viên nghỉ học trong tháng này. Thầy/Cô gửi học viên một số tài liệu tự học nhé!!";

export const remindAttendanceLast = (is_ecoach,value) => {
  let day_remain = `Còn ${value} buổi học nữa là đến buổi kiểm tra.<br><br>`;
  let remind = is_ecoach ? REMIND_ATTENDANCE_LAST_ECOACH : REMIND_ATTENDANCE_LAST_OTHER;
  return day_remain + remind;
}
