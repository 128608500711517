<template>
  <el-row class="container">
    <div class="border border-dark" id="table" >
    <el-row class="table-responsive-lg">
      <p class="p-2 bg-light "><span class="bold">Reading:</span> {{ item.reading }}</p>
      <p class="p-2 bg-light "><span class="bold">Grammar:</span> {{ item.grammar }}</p>
      <p class="p-2 bg-light "><span class="bold">Speaking:</span> {{ item.speaking }}</p>
      <p class="p-2 bg-light "><span class="bold">Writing:</span> {{ item.writing }}</p>
      <p class="p-2 bg-light "><span class="bold">Vocabulary:</span> {{ item.vocabulary }}</p>
      <p class="p-2 bg-light "><span class="bold">Listening:</span> {{ item.listening }}</p>
      <p class="p-2 bg-light "><span class="bold">Material:</span> {{ item.material }} </p>
    </el-row>
    </div>
    <div class="title">
      <h3 class="mt-2"><b>Slide giảng dạy</b></h3>
    </div>
    <div class="title mb-3">
      <el-dropdown size="small" trigger="click" v-if="item.media_slide"  type="success-100" class="p-0">
        <el-button type="success-100" >
            Mở Slide<i type="success-100" class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown" type="success-100">
          <el-dropdown-item v-for="(data, index) in slides" :key="index" :command="index" type="success-100">
            <a class="text-dark btn w-100" style="text-decoration: none!important;" @click="handleLearningSchedule(index)" v-if="data.type_slide == 3" target="_blank" :href="data.uri_slide"><span style="float: inline-start">{{data.name_slide}}</span></a>
            <button @click="handleCommand(data)" v-else class="btn w-100">
              <span style="float: inline-start; margin-left:5px">{{data.name_slide}}</span>
            </button> 
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <router-link v-else-if="  slides.length === 1" class="btn p-0" :to="{name : 'content-slide', params:{ id: item.learning_schedule_id, classroom_id: classroom_id,start_lesson: item.date } }">
      <el-button type="success-100" >
        Mở slide
      </el-button>
      </router-link>

      <el-button v-else type="warning">Chưa có slide</el-button>

      <p>
        <el-button type="text" @click="show">Lịch sử mở slide</el-button>
      </p>

    </div>
    <span class="dialog-footer mt-5 d-flex align-items-center justify-content-end">
            <button class="btn btn-outline-default ml-2" @click="close">Đóng</button>
        </span>
  </el-row>
</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {mapGetters} from "vuex";
import store from "@/core/services/store";
import { SHOW_DETAIL_LEARNING_SCHEDULE } from "@/core/services/store/classes/classes.module";

const _ = deepdash(lodash);

export default {
  components: {
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      classroom_id: '',
      slides: []
    }
  },
  mounted() {
    this.classroom_id = this.$route.params.id;
  },
  computed: {
    ...mapGetters['currentUser'],
  },
  watch: {
    item: {
      handler() {
        if (this.item.media_slide){
          this.slides = this.item.media_slide
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    
    
    close: function () {
      this.$emit('close');
    },
    handleCommand(item) {
      const url = this.$router.resolve({
        name: 'content-slide',
        params: { 
          id: item.model_id,  
          classroom_id: this.classroom_id 
        },
        query: { 
          slide_id: item.id 
        }
      }).href;
      window.open(url, '_blank'); 
    },

    show() {
      this.$emit('show',true);
    },
    handleLearningSchedule(index)
    {
      let payload = {
        schedule_id: this.item.learning_schedule_id,
        classroom_id: this.classroom_id,
        start_lesson: this.item.date,
        slide_id: this.slides[index].id,
      };
      this.$store
        .dispatch(SHOW_DETAIL_LEARNING_SCHEDULE, payload)
        .then((res) => {
        });
    }
  },
}
</script>
<style scoped>
#table {
  border-collapse: separate;
  border-spacing: 15px 15px;
}
</style>
<style>
.el-button--success-100 {
  color: #FFF;
  background-color: 	#886ab5;
  border-color: 	#886ab5;
}
.el-button--success-100:hover {
  color: #FFF;
  background-color: 	#886ab5;
  border-color: 	#886ab5;
}
</style>
