<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <div class="row mb-3">
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-start">
                <div class="dt-buttons">
                  <router-link :to="{ name: 'classrooms'}" v-slot="{ href, navigate }">
                    <a :href="href" @click="navigate" class="btn btn-dark btn-sm mr-1" tabindex="0"
                       aria-controls="dt-basic-example" type="button"><span><i
                        class="fal fa-chevron-left mr-1"></i> Trở lại trang quản lý lớp học</span>
                    </a>
                  </router-link>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div class="dt-buttons">
                  <button class="btn btn-success btn-sm mr-1" tabindex="0" @click="openPhuDao"
                          aria-controls="dt-basic-example" type="button"><span><i
                      class="fal fa-book mr-1"></i> Tạo buổi học phụ đạo</span>
                  </button>
                  <button v-if="currentLesson !== 1 && has_warning" class="btn btn-danger btn-sm mr-1" tabindex="0" @click="actionWarning(true)"
                          aria-controls="dt-basic-example" type="button"><span><i
                      class="fal fa-book mr-1"></i>Nhắc nhở học viên</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row m-2 justify-content-between">
              <schedule-item
                  v-for="(item, index) in class_info" :key="index"
                  :item="item"
                  :index="index"
                  :items="class_info"
                  @attendance="openDialogAttendance"
                  @comment="checkOpenDialogComment"
                  @content="openDialogContent"
                  @give-gift="openDialogGiveGift"
                  @edit="openEditPhuDao"
                  @view-homework="checkOpenDialogViewHomeWork"
                  @mark-homework="checkOpenDialogMarkHomeWork"
                  @tutoringSuccess="tutoringSuccess"
                  @examResult="openExamResult"
                  @openAttendancePhuDao="openAttendancePhuDao"
                  @openCommentPhuDao="openDialogCommentPHuDao"
                  @openOffLesson="openOffLesson"
                  @cancelOffLesson="cancelOffLessson"
                  @show-data-class="switchUploadDataClass"
              >
              </schedule-item>
            </div>
            <div class="row mb-5"></div>
          </div>
        </div>
      </div>
    </div>
    <!--  Điểm danh -->
    <el-dialog
        :title="dialog.title"
        class="responsive"
        :visible.sync="dialogAttendance"
    >
      <attendance
          @close="closeDialog()"
          @changeAttendance="changeAttendance"
          @attendancePost="attendancePost"
          @attendancePostPhuDao="attendancePostPhuDao"
          :attendance_lesson="attendance_lesson"
          :use_update="use_update"
          :type_lesson="type_lesson"
          :type_attendance="dialog.type_attendance"
          :disableButton="dialog.disable"
          :currentDate="dialog.currentDate"
          :is_disable_button_attendance="is_disable_button_attendance"
          :is_show_attendance="is_show_attendance"
          :isExam="item.exam && item.exam != '0'"
      ></attendance>
    </el-dialog>
    <el-dialog title="Nhập lý do nghỉ học" :visible.sync="dialogReason">
      <table class="table table-bordered m-0">
        <thead>
        <tr>
          <th> Họ và tên</th>
          <th> Mã học viên</th>
          <th class="text-center"> Lý do</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in studentRegister" :key="index">
            <td>{{item.user_name}}</td>
            <td>{{item.user_code}}</td>
            <td>
              <input type="text" class="form-control" v-model="item.reason">
              <span v-if="!item.reason" class="text-danger">Lý do không được để trống</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end mt-4">
        <el-button class="btn btn-outline-default ml-2" @click="dialogReason = false, dialogAttendance = false">Đóng</el-button>
        <el-button class="btn btn-outline-default ml-2 " type="primary" v-loading="loadingSubmit" @click="submint">Xác nhận</el-button>
      </div>
    </el-dialog>
    <!--  Nhận xét -->
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogComment">
      <comment-student
          @close="closeDialog()"
          :comments="comments"
          :type_comment="dialog.type_comment"
          :time_comment="time_comment"
          :checkError="checkError"
          :loadingPost="loadingPost"
          @changeComment="changeComment"
          @postComment="postComment"
          @postCommentPhuDao="postCommentPhuDao"
          @changeClassify="changeClassify"
          v-loading="loadingComment"
      >
      </comment-student>
    </el-dialog>

    <!--  Nội dung -->
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogContent">
      <content-lesson
          @close="closeDialog()"
          :item="item"
          @show="show"
      >
      </content-lesson>
    </el-dialog>

    <!--  Tặng quà -->
    <el-dialog
        class="gift"
        :title="dialog.title"
        :visible.sync="gift_data.dialogGiveGift"
    >
    <give-gift v-loading="gift_data.isLoadingGiveGift"
    :listStudentAttendance="gift_data.listStudentAttendance"
    :studentGetGift="gift_data.studentGetGift"
    :dialogGiveGift="gift_data.dialogGiveGift"
    @select-student="selectStudent"
    @radio="radioType"
    >
    </give-gift>
      <span v-if="!gift_data.isLoadingGiveGift" slot="footer" class="dialog-footer">
        <el-button :disabled="gift_data.disableSubmit" v-if="gift_data.studentGetGift.length == 0" :loading="gift_data.loadingSubmit" type="primary" @click="giveGift">Xác nhận</el-button>
        <el-button @click="gift_data.dialogGiveGift = false">Đóng</el-button>
      </span>
    </el-dialog>

    <!--  Tặng quà thành công -->
    <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="gift_data.dialogGiftSuccess">
            <gift @close="closeDialog()" :idMember="dialog.id" v-if="gift_data.dialogGiftSuccess" :nameMember="gift_data.name"></gift>
    </el-dialog>

    <el-dialog
        title="Lịch sử mở slide"
        :visible.sync="showHistory"
        width="50%"
    >
      <history-open-slide :dataTable="dataTimeOpenSlide"></history-open-slide>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showHistory = false">Đóng</el-button>
      </span>
    </el-dialog>

    <!--  Bài tập về nhà -->
    <el-dialog :destroy-on-close="true" :title="dialog.title" class="responsive" :visible.sync="dialogHomework" @close="checkLastSubnission(dialog.lesson)">
      <home-work @close="closeDialog()" @dialogClose="dialogClose()" :item="dialog.lesson" @openHomeWorkExam="openHomeWorkExam"></home-work>
    </el-dialog>
    <!-- Xem và chấm Bài tập về nhà -->
    <el-dialog :destroy-on-close="true" :title="dialog.title" class="responsive" :visible.sync="dialogHomeworkExam">
      <home-work-exam v-if="dialogHomeworkExam" @close="closeDialog()" :learning_schedule_id="dialog.learning_schedule_id"
                      :type-show="dialog.typeShow" @martSuccess="martSuccess" :student="dialog.student"
                      :data="dialog.data" :lesson="dialog.currentLesson"></home-work-exam>
    </el-dialog>

    <el-dialog
        title="Xác nhận đóng"
        :visible.sync="dialogCloseHomework"
        width="30%"
        style="z-index: 0 !important;">
      <p>Bạn chưa chấm hết BTVN cần chấm.</p>
      <p>Bạn chắc chắn muốn đóng chứ?</p>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogCloseHomework = false">Hủy bỏ</el-button>
          <el-button type="primary" @click="closePopup">Xác nhận</el-button>
        </span>
    </el-dialog>

    <!--  Bài kiểm tra -->
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogExamResult">
      <exam v-if="dialogExamResult" @close="closeDialog()" :result_exams="result_exams" :dataExam="dialog.data"></exam>
    </el-dialog>

    <!--  Buổi học phụ đạo -->
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogPhuDao">
      <tutoring @close="closeDialog()" :action="action" @tutoringSuccess="tutoringSuccess"></tutoring>
    </el-dialog>
    <!--  Sửa buổi học phụ đạo -->
    <el-dialog :title="dialog.title" class="responsive" :visible.sync="dialogEditPhuDao">
      <edit-tutoring @close="closeDialog()" :tutoring_id="dialog.id" @tutoringSuccess="tutoringSuccess"></edit-tutoring>
    </el-dialog>

    <!-- Yêu cầu nghỉ học  -->
    <el-dialog title="Yêu cầu nghỉ học" class="responsive" :visible.sync="dialogRequestOffLesson">
      <el-form label-position="top" label-width="20%">
        <span v-if="item.schedule_id != 43">Buổi học:<b> {{ item.lesson }} </b> sẽ được chuyển sang ngày <b>{{ next_lesson_date }} </b></span>
        <el-form-item label="Nhập lý do nghỉ buổi học">
          <el-input
              type="textarea"
              :rows="4"
              placeholder="Nhập lý do nghỉ"
              v-model="reason_off"></el-input>
        </el-form-item>
        <el-form-item class="text-right">
          <el-button @click="dialogRequestOffLesson = false">Hủy</el-button>
          <el-button type="primary" @click="submitOffClass">Gửi yêu cầu</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- dialog Nhắc nhở giảng viên thiếu tư liệu -->
    <el-dialog id="dialog_upload_notification" title="Nhắc nhở" :visible.sync="dialogNoficationDataClass" style="color: #000; word-break: break-word">
        <div class="title-delete" >
          <hr>
            <div class="container">
                <div class="row" style="word-break: break-word;">
                  <div class="col-12">
                    <span>Hiện tại lớp học đang chưa đủ số lượng tư liệu ảnh/video về hoạt động của lớp</span>
                  </div>
                  <div class="col-12">
                    <p>Số lượng ảnh/video tối thiểu cần bổ sung <span style="color: blue;"><b>{{ DialogNotification.owe_file_upload }} ấn phẩm</b></span></p>
                  </div>
                  <div class="col-12">
                    <p>Thầy/cô vui lòng bổ sung trước<span style="color: rgb(255, 0, 0);"><b> {{ DialogNotification.end_timeNotification + " - 23:59:59" }}</b></span></p>
                  </div>
                </div>
              </div>
          </div>
        <span slot="footer" class="dialog-footer d-flex justify-content-end btn-footer">
            <el-button class="p-2 m-1"  style="color: #000; background-color: #E6E6E6;" @click="dialogNoficationDataClass = false">Để sau</el-button>
            <el-button class="p-2 m-1" style="color: #fff; background-color: #500DA7;" type="primary" @click="switchUploadDataClass">Bổ sung ngay</el-button>
        </span>
    </el-dialog>
    <div class="support-class">
      <transition name="fade">
          <div v-show="warning.is_open" class="support-class-noti">
              <div class="noti-container">
                <b>Nhắc nhở học viên</b>
                <div v-if="warning.message.type == 'normal'">
                  <div v-html="messages.leaveMessage"></div>
                  <div v-html="messages.homeworkMessage"></div>
                  <div v-html="messages.badScoreMessage"></div>
                </div>

                <div v-if="warning.message.type == 'test'">
                  <div>
                    <div v-html="messages.leaveTestMessage"></div>
                  </div>
                  <br>
                  <div>
                    <span v-html="REMIND_HOMEWORK_TEST"></span><span @click="seeNow(5)" class="see-now"> Xem ngay</span>
                  </div>
                </div>
                <div v-if="warning.message.type == 'last_month'">
                  <div v-if="warning.message.count_leave > 0">
                    <span v-html="remind"></span><span @click="seeNow(1)" class="see-now"> Xem ngay</span>
                  </div>
                  <div v-html="messages.homeworkMessage"></div>
                </div>
              </div>
            <div @click="actionWarning(false)" class="el-notification__closeBtn el-icon-close"></div>
          </div>
        </transition>
    </div>
    <el-dialog
        :visible.sync="dialogDetailWarning"
        :title="titleModal"
        width="500"
        :show-close="false"
    >
      <hr style="margin-top: -20px"/>
      <TableModal
          :loading = "loadingDetail"
          :data-table="dataTable"
          :type-card="criteria_type"
          :table-header="tableHeader"
      />
      <div class="d-flex justify-content-end mt-4">
        <button type="button" class="btn btn-secondary" @click="dialogDetailWarning = false">Đóng</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ScheduleItem from "@/views/pages/classrooms/schedule-item";
import Attendance from "@/views/pages/classrooms/attendance";
import CommentStudent from "@/views/pages/classrooms/comment-student";
import ContentLesson from "@/views/pages/classrooms/content-lesson";
import HomeWork from "@/views/pages/classrooms/homework";
import HomeWorkExam from "@/views/pages/classrooms/homeworkexam";
import Exam from "@/views/pages/classrooms/examResult";
import Tutoring from "@/views/pages/classrooms/tutoring";
import EditTutoring from "@/views/pages/classrooms/edit-tutoring";
import HistoryOpenSlide from "@/views/pages/classrooms/historyOpenSlide";
import GiveGift from "@/views/pages/classrooms/give-gift";

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {mapGetters} from "vuex";
import {
  ACTION_ATTENDANCE,
  ATTENDANCE_LESSON,
  CLASSROOM_SCHEDULE,
  LIST_COMMENT,
  POST_COMMENT,
  ATTENDANCE_LESSON_PHU_DAO,
  ACTION_ATTENDANCE_PHU_DAO,
  POST_COMMENT_PHU_DAO,
  RESULT_EXAM,
  REQUEST_OFF_LESSON,
  CANCEL_REQUEST_OFF_LESSON,
  GET_TIME_OPEN_SLIDE,
  GET_INFO_DATA_UPLOAD_CLASS,
  GET_NEXT_DATE_LESSON, CHECK_ATTENDANCES, CHECK_COMMENT, CHECK_LAST_SUBMISSION, STUDENT_HAS_ATTENDANCE, STUDENT_HAS_GIFT,
  READY_MARK_HOMEWORK
} from "@/core/services/store/classes/classes.module";
import {GIVE_GIFT} from "@/core/services/store/service.module";
import Gift from "@/views/pages/team/gift";
const _ = deepdash(lodash);
import {
  countLeaveHomeworkStudent,
  REMIND_ATTENDANCE_TEST,
  REMIND_HOMEWORK_TEST,
  remindAttendanceLast
} from '@/core/config/warning_class';
import {GET_WARNING_CLASS} from "@/core/services/store/supportClass/supportClass.module";
import {
  headerModalDiligence,
  headerModalMeetRequirements
} from "@/views/pages/home/model/ClassOfUser";
import TableModal from "@/views/pages/home/component/TableModal.vue";
import {GET_DETAIL_CLASS_INFO} from "@/core/services/store/supportClass/supportClass.module";
import moment from 'moment';
export default {
  components: {
    EditTutoring,
    ContentLesson,
    ScheduleItem,
    Attendance,
    CommentStudent,
    HomeWork,
    Exam,
    Tutoring,
    HomeWorkExam,
    HistoryOpenSlide,
    GiveGift,
    Gift,
    TableModal
  },
  data() {
    return {
      dialogReason: false,
      studentRegister: [],
      attendance_lesson: [],
      result_exams: [],
      comments: [],
      class_info: [],
      infoDataUpload: [],
      item: {},
      index: 0,
      dialogAttendance: false,
      dialogComment: false,
      dialogContent: false,
      dialogHomework: false,
      dialogExam: false,
      dialogExamResult: false,
      dialogPhuDao: false,
      dialogEditPhuDao: false,
      dialogHomeworkExam: false,
      dialogRequestOffLesson: false,
      dialogNoficationDataClass: false,
      action: '',
      DialogNotification: {
       startTimeNotification: '',
       end_timeNotification: '',
       owe_file_upload: '',
       deathline_nofi: '',
      },
      dialog: {
        title: null,
      },
      reason_off: '',
      next_lesson_date: '',
      is_disable_button_attendance: true,
      dialogCloseHomework: false,
      use_update: '',
      showHistory: false,
      dataTimeOpenSlide: [],
      time_comment: '',
      is_show_attendance: true,
      classify: [],
      checkError: '',
      checkAttendances: '',
      type_lesson: '',
      checkClose: false,

      // action tặng quà nha
      gift_data : {
        isLoadingGiveGift: true,
        dialogGiftSuccess : false,
        dialogGiveGift:false,
        listStudentAttendance: [],
        studentGetGift : [],
        student_id : 0,
        type_radio :1,
        loadingSubmit : false,
        disableSubmit : false,
      },
      loadingComment: false,
      loadingPost: true,
      listLesson: [],
      lesson: 0,
      warning : {
        is_open: false,
        message : ''
      },
      countLeaveHomeworkStudent,
      REMIND_ATTENDANCE_TEST,
      REMIND_HOMEWORK_TEST,
      remindAttendanceLast,
      titleModal: '',
      dataTable: [],
      tableHeader: [],
      headerDiligence: headerModalDiligence,
      headerMeetRequirements: headerModalMeetRequirements,
      dialogDetailWarning : false,
      criteria_type : 0,
      currentLesson : 0,
      loadingDetail : true,
      has_warning : false,
      loadingSubmit: false
    }
  },
  mounted() {
    let class_name = localStorage.getItem('className');
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: `Quản lý học tập lớp: ${class_name}`, icon: 'far fa-tasks'}
    ]);
    this.querySever();
    this.openDialogNofication();
    this.getWarningClass();
  },
  computed: {
    ...mapGetters(['currentUser', 'currentTitle']),
    checkShowPhase() {
      return this.getCurrentPhase !== undefined;
    },
    getCurrentPhase() {
      const phaseDefault = this.getPhaseDefault;
      return phaseDefault?.find(d => d.lesson === this.lesson);
    },
    getPhaseDefault() {
      const listDateLesson = this.listLesson?.map((date, index) => {
        return {
          index: index,
          lesson: date.lesson,
          date: date.date_only,
          type: date.type
        }
      })
      const phaseDefault = [6, 12, 18, 24, 28, 32, 36, 40]
      return phaseDefault.map(phase => {
        let current = listDateLesson?.find(lesson => lesson.lesson === phase)
        if (!current) {
          return null
        }
        if (current.type === 'homework') {
          return current
        }
        return listDateLesson?.find(lesson => lesson?.lesson === phase + 1)
      }).filter(d => d !== null && d !== undefined);
    },
    messages() {
      const attendances = this.warning?.message?.attendances || [];
      const homeworks = this.warning?.message?.homeworks || [];
      const badscore = this.warning.message.badScore || [];
      const leaveTest = this.warning.message.attendances_test || [];
      const leaveMessage = attendances.length > 0 ? countLeaveHomeworkStudent(attendances.length, attendances, 'attend') : '';
      const homeworkMessage = homeworks.length > 0 ?  countLeaveHomeworkStudent(homeworks.length, homeworks, 'homework') : '';
      const badScoreMessage = badscore.length > 0 ?  countLeaveHomeworkStudent(badscore.length, badscore, 'badscore') : '';
      const leaveTestMessage = leaveTest.length > 0 ?  countLeaveHomeworkStudent(leaveTest.length, leaveTest, 'leaveTest') : '';
      return {
        leaveMessage,
        homeworkMessage,
        badScoreMessage,
        leaveTestMessage
      };
    },

    remind() {
      const day_remain = this.warning?.message?.day_remain ?? 0;
      const is_ecoach = this.warning?.message?.center_id == 295;
      return remindAttendanceLast(is_ecoach, day_remain);
    }
  },
  methods: {
      async getListDetailClassInfo(type) {
        this.loadingDetail = true;
        this.dialogDetailWarning = true
        const payload = {
          class_id: this.$route.params.id,
          type: type,
          is_detail: 1
        }
        const response = await this.$store.dispatch(GET_DETAIL_CLASS_INFO, payload)
        this.dataTable = response?.data?.data ?? [];
        this.loadingDetail = false;
      },
      hasWarningOrNot(warning) {
        
        if(warning.type == 'normal') {
          
          if(warning.attendances.length > 0 || warning.homeworks.length > 0 ||  warning.badScore.length > 0) {
            return true;
          }
          return false;
        }

        if(warning.type == 'test') {
          if(warning.count_leave > 0 || warning.count_homework > 0 ||  warning.badscore.length > 0 ||  warning.leaveTest.length > 0) {
            return true;
          }
          return false;
        }

        if(warning.type == 'last_month') {
          if(warning.count_leave > 0 || warning.homeworks.length >  0 || warning.badscore.length > 0) {
            return true;
          }
          return false;
        }
      },
      seeNow(type){
        this.criteria_type = type;
        switch (type) {
          case 1:
            this.titleModal = 'Danh sách học viên nghỉ học '
            this.tableHeader = this.headerDiligence
                break;
          case 5:
            this.titleModal = 'Danh sách học viên không hoàn thành BTVN'
            this.tableHeader = this.headerMeetRequirements
                break;
        }
        this.getListDetailClassInfo(type);
      },
      getWarningClass() {
        this.$store.dispatch(GET_WARNING_CLASS, { classroom_id: this.$route.params.id }).then((res) => {
          this.warning.message = res.data.data;
          this.warning.is_open = this.has_warning = this.hasWarningOrNot(res.data.data);
        }).catch((error) => {
          console.log("error",error);
        })
      },
      actionWarning(is_open = false) {
        if(is_open) {
          this.getWarningClass();
        }
        this.warning.is_open = is_open;
      },
      checkAllAlike() {
          let comments = this.comments;
          let count = 0;
              this.comments.map(function (value) {
              if (comments[0].classify == value.classify) { count ++; }
          });
          if (this.comments.length === count && this.comments.length != 1) {
              return true
          }
          return false;
      },

      checkAllLengthByClassify() {
          let arr = [];
          this.comments.filter(d => {
              if ([0, 1, 2, 3].includes(d.classify) && d.comment.length < 150 || d.classify == '') {
                  arr.push(d.user_code);
              }
          });
          return arr;
      },
    checkOpenDialogComment(item) {
      this.lesson = item.lesson
      this.loadingPost = true;
      this.$store.dispatch(CHECK_ATTENDANCES, {
        classroom_id: this.$route.params.id,
        lesson: item.lesson
      }).then(res => {
        if (!res.error) {
          if(res.data === 0) {
            this.$message({
              type: 'error',
              message: 'Không thể nhận xét do chưa điểm danh.'
            });
          } else {
            this.$store.dispatch(ATTENDANCE_LESSON, {
              classroom_id: this.$route.params.id,
              lesson: item.lesson,
            }).then((res) => {
              const attendanceNone = res.data.filter(item => item.attendance !== 1)
              if(this.checkShowPhase || attendanceNone.length < res.data.length) {
                this.$router.push({
                  name: 'comment-student',
                  params: {id: item.lesson},
                  query: {
                    classroom_id: this.$route.params.id,
                    phase: this.checkShowPhase
                  }
                })
              }else {
                this.$message({
                  message: 'Giảng viên không cần thực hiện tác vụ nhận xét do tất cả học viên đều vắng học.',
                  type: 'error'
                });
              }
            });
          }
        }
      })
    },
    

    // Chấm BTVN
    checkOpenDialogMarkHomeWork(item) {
      this.$store.dispatch(READY_MARK_HOMEWORK, {
        classroom_id: this.$route.params.id,
        lesson: item.lesson
      }).then((res) => {
        if (!res.error) {
          if (res.data) {
            // this.openHomeWork(item)
            const routeData = this.$router.resolve({ name: "list_mark_homework",params: {id : this.$route.params.id},
              query: { classroom_id: this.$route.params.id, lesson: item.lesson , learning_schedule_id: item.learning_schedule_id, center_id: item.center_id}});
            window.open(routeData.href, '_blank');
            return;
          }
          this.$message({
            type: 'error',
            message: 'Không thể chấm điểm do chưa tặng quà.'
          });
        }
        this.$message({
            type: 'error',
            message: 'Không thể chấm điểm do chưa tặng quà.'
          });
      });
    },
    // Xem BTVN
    checkOpenDialogViewHomeWork(item){
      const routeData = this.$router.resolve({ name: "content-homework",params: {id : item.lesson},
                 query: { classroom_id: this.$route.params.id, learning_schedule_id:item.learning_schedule_id  }});
                 window.open(routeData.href, '_blank');
    },
    /**
     * Call api đánh giá học viên
     */
    postComment(loading) {
      this.loadingComment = true;
      // ver 2 nhan xet
      if (this.checkAllAlike()) {
        this.loadingComment = false;
        this.checkError = 'Cảnh báo lỗi: Bạn đang chọn một loại nhận xét cho tất cả học viên!';
        this.loadingPost = true;
        return;
      }
        this.checkError = '';
        if (this.checkAllLengthByClassify().length) {
          this.loadingPost = true;
          this.loadingComment = false;
          return;
      }
      this.loadingPost = false;
      this.$store.dispatch(POST_COMMENT, this.getPayloadPostComment()).then((res) => {
        if (!res.error) {
          this.$message({
            message: 'Nhận xét thành công',
            type: 'success'
          });
          this.closeDialog();
          // this.openDialog(this.item);
        } else {
            this.loadingComment = false
            this.$message.error('Oops, Đánh giá thất bại.');
        }
      }).finally(() => {
          this.loadingComment = false
      });
    },
    /**
     * Call api đánh giá học viên buổi phụ đạo
     */
    postCommentPhuDao() {
      let newData = this.getPayloadPostComment().data.map(item => {
        return {course_student_id: item.id, comment: item.comment}
      })
      let payload = {
        tutoring_lesson_id: this.item.id,
        data: newData,
      };
      this.$store.dispatch(POST_COMMENT_PHU_DAO, payload).then((res) => {
        if (!res.error) {
          this.$message({
            message: 'Nhận xét thành công',
            type: 'success'
          });
          this.closeDialog();
          // this.openDialog(this.item);
        } else {
          this.$message.error('Oops, Đánh giá thất bại.');
        }
      });
    },

    changeComment(item, value) {
      this.comments.map((v, k) => {
        if (v.id == item.id) {
          return this.comments[k].comment = value;
        }
      })
    },
    changeClassify(item, value) {
      this.comments.map((v, k) => {
        if (v.id == item.id) {
          return this.comments[k].classify = value;
        }
      })
        if (this.checkAllAlike()) {
            this.checkError = 'Cảnh báo lỗi: Bạn đang chọn một loại nhận xét cho tất cả học viên!';
            return;
        }
        this.checkError = '';
        return;
    },
    getType(item) {
      if (item.exam != 0) {
        return 'test';
      }
      return 'lesson';
    },

    /**
     * Call api điểm danh
     */
    attendancePost() {
      let payload = this.getPayloadAttendance();
      this.handleData(payload.data);
      return;
    },

    /**
     * Call api điểm danh phụ đạo
     */
    attendancePostPhuDao() {
      let data = this.getPayloadAttendance();
      let payload = {
        tutoring_lesson_id: this.item.id,
        data: data.data,
      };
      this.$store.dispatch(ACTION_ATTENDANCE_PHU_DAO, payload).then(() => {
        this.$message({
          message: 'Điểm danh thành công',
          type: 'success'
        });
        this.closeDialog();
        this.dialog.disable = false;
      }).catch((res) => {
        this.dialog.disable = false;
        this.$message({
          message: res.data.message,
          type: 'error'
        });
      });
    },

    /**
     * Get payload comment post
     * @returns {{classroom_id: string, data: *[], lesson: *}}
     */
    getPayloadPostComment() {
      let data = [];
      this.comments.map((value) => {
        data.push({
          id: value.id,
          comment: value.comment,
          classify: value.classify,
        })
      });
        return {
        classroom_id: this.$route.params.id,
        lesson: this.item.lesson,
        data: data
      };
    },

    /**
     * Get payload attendance
     * @returns {{classroom_id: string, data: *[], lesson: *}}
     */
    getPayloadAttendance() {
      let data = [];
      this.attendance_lesson.map((value => {
        data.push({
          course_student_id: value.id,
          val: value.attendance
        })
      }));
      return {
        startTimeLesson: this.item.date_only,
        startTime: this.item.start_time,
        classroom_id: this.$route.params.id,
        lesson: this.item.lesson,
        greaterComingLesson: this.item.greaterComingLesson,
        type: this.item.type,
        data: data,
        is_show_phase:this.checkShowPhase
      }
    },

    /**
     * Xử lý data khi click điểm danh
     * @param type
     * @param course_student_id
     */
    changeAttendance(type, course_student_id) {
      this.attendance_lesson.map((value, key) => {
        if (value.id == parseInt(course_student_id)) {
          this.attendance_lesson[key].attendance = type;
        }
      })
      this.checkDisableAttendance();
    },
    checkDisableAttendance() {
      this.is_disable_button_attendance = false;
      for (let i = 0; i < this.attendance_lesson.length; i++) {
        if (this.attendance_lesson[i].attendance === null && !this.attendance_lesson[i].status_class) {
          this.is_disable_button_attendance = true;
          break;
        }
      }
    },
    checkShowAttendance() {
      if (this.attendance_lesson[0].attendance != null) {
        this.is_show_attendance = false;
      } else {
        this.is_show_attendance = true;
      }
    },
    closeDialog() {
      this.dialogAttendance = false;
      this.dialogComment = false;
      this.dialogContent = false;
      this.dialogHomework = false;
      this.dialogExam = false;
      this.dialogExamResult = false;
      this.dialogPhuDao = false;
      this.dialogEditPhuDao = false;
      this.dialogHomeworkExam = false;
      this.action = '';
      this.dialogRequestOffLesson = false
      this.gift_data.dialogGiveGift = false
      this.gift_data.dialogGiftSuccess = false;
    },

    openDialogAttendance(item,greaterComingLesson) {
      this.dialog.title = 'Điểm danh: Buổi ' + `${item.lesson}`;
      this.dialog.currentDate = item.date;
      this.item = item;
      this.type_lesson = item.type;
      this.item.greaterComingLesson = greaterComingLesson;
      this.dialog.type_attendance = '';
      this.dialogAttendance = true;
      this.dialog.disable = true;
      this.lesson = item.lesson;
      this.$store.dispatch(ATTENDANCE_LESSON, {
        classroom_id: this.$route.params.id,
        lesson: this.item.lesson
      }).then((res) => {
        if (!res.error) {
          this.attendance_lesson = res.data;
          this.use_update = this.attendance_lesson[0] && this.attendance_lesson[0].user_update ?
              this.attendance_lesson[0].user_update[0] + ' bởi ' + (this.attendance_lesson[0].user_update[1].id + 100000) + ' - ' + this.attendance_lesson[0].user_update[1].name
              : '';
          this.checkShowAttendance();
          this.checkDisableAttendance();
        }
      });
    },

    openAttendancePhuDao(item) {
      let date = item.date ? item.date.split("-").reverse().join("-") : '';
      this.dialog.title = 'Điểm danh buổi phụ đạo: ' + `Ngày ${date}`;
      this.dialog.currentDate = item.date;
      this.item = item;
      this.dialogAttendance = true;
      this.dialog.type_attendance = 'Buổi phụ đạo';
      this.dialog.disable = true;
      this.$store.dispatch(ATTENDANCE_LESSON_PHU_DAO, item.id).then((res) => {
        if (!res.error) {
          this.attendance_lesson = res.data;
        }
      }).catch((res) => {
        this.attendance_lesson = [];
      });
    },

    openDialogComment(item) {
      this.comments = [];
      this.dialog.title = 'Nhận xét: Buổi ' + `${item.lesson}`;
      this.dialog.type_comment = '';
      this.dialogComment = true;
      this.comments = [];
      this.item = item;
      this.$store.dispatch(LIST_COMMENT, {
        classroom_id: this.$route.params.id,
        lesson: item.lesson
      }).then((res) => {
        if (!res.error) {
          this.comments = res.data;
          this.time_comment = this.comments && this.comments[0] && this.comments[0].user_comment ? this.comments[0].user_comment_time + ' bởi ' + (this.comments[0].user_comment.id + 100000) + ' - ' + this.comments[0].user_comment.name : '';
        }
      });
    },

    openDialogCommentPHuDao(item) {
      let date = item.date ? item.date.split("-").reverse().join("-") : '';
      this.dialog.title = 'Nhận xét buổi phụ đạo: ' + `Ngày ${date}`;
      this.dialogComment = true;
      this.dialog.type_comment = 'Buổi phụ đạo';
      this.item = item;
      this.$store.dispatch(ATTENDANCE_LESSON_PHU_DAO, item.id).then((res) => {
        if (!res.error) {
          this.comments = res.data;
        }
      });
    },

    openDialogContent(item) {
      this.dialog.title = 'Nội dung bài học: Buổi ' + `${item.lesson}`;
      this.item = item;
      this.dialogContent = true;
    },

    async showDialogGiveGift(item) {
      this.gift_data.dialogGiveGift = true;
      this.gift_data.isLoadingGiveGift = true;
      this.dialog.title = 'Tặng quà: Buổi ' + `${item.lesson}`;
      this.item = item;
      await this.$store.dispatch(STUDENT_HAS_ATTENDANCE, {
        classroom_id: this.$route.params.id,
        lesson: this.item.lesson
      }).then((res) => {
        if (!res.error) {
          this.gift_data.listStudentAttendance = res.data;
        }
      });
      await this.$store.dispatch(STUDENT_HAS_GIFT, {
        classroom_id: this.$route.params.id,
        lesson: this.item.lesson
      }).then((res) => {
        if (!res.error) {
          this.gift_data.studentGetGift = res.data;
        }
      });
      this.gift_data.isLoadingGiveGift = false;
    },
    openDialogGiveGift(item) {
      this.lesson = item.lesson
      this.$store.dispatch(CHECK_COMMENT, {
        classroom_id: this.$route.params.id,
        lesson: item.lesson
      }).then((res) => {
        if (!res.error) {
          if (res.data) {
            if((this.checkShowPhase && res.data?.count_comment >= res.data?.student_in_class) || (!this.checkShowPhase && res.data?.count_comment >= res.data?.student_attend && res.data?.student_attend > 0 && res.data?.count_comment > 0)) {
              this.showDialogGiveGift(item)
            } else {
              this.$message({
                type: 'error',
                message: 'Không thể tặng quà do chưa nhận xét.'
              });
            }
          }
        }
      });
    },

    selectStudent(student_data){
      if(student_data) {
        if(this.gift_data.type_radio == 1) {
          this.gift_data.disableSubmit = false;
        }
        this.gift_data.student_id = student_data.id;
        this.gift_data.clan_id = student_data.clan_id;
        this.gift_data.name = student_data.name;
      } else {
        if(this.gift_data.type_radio == 1) {
          this.gift_data.disableSubmit = true;
        }
      }
    },
    radioType(type){
      this.gift_data.type_radio = type;
      if(type == 2) {
        this.gift_data.student_id = 0;
        this.gift_data.disableSubmit = false;
      } else {
        this.gift_data.disableSubmit = true;
      }
    },
    giveGift(){
      this.gift_data.loadingSubmit = true;
      this.gift_data.disableSubmit = true;
      let params = {
        user_id: this.gift_data.student_id,
        user_clan_id:this.gift_data.clan_id,
        classroom_id:this.$route.params.id,
        lesson_id:this.item.lesson
      }
      let message = '';
      if(params.user_id == 0) {
        message = 'Thao tác thành công!'
      } else {
        this.gift_data.dialogGiftSuccess = true;
        message = 'Tặng quà thành công!'
      }
      this.$store.dispatch(GIVE_GIFT, params)
            .then(() => {
                    this.$message({
                      type: 'success',
                      message: message,
                      showClose: true
                    });
              this.dialog.title = `Thưởng`
              this.gift_data.loadingSubmit = false;
              this.gift_data.disableSubmit = false;
              this.gift_data.dialogGiveGift = false;
            }).catch((response) => {
                  this.$notify({
                    title: 'Warning',
                    message: `Serve đang bận, vui lòng thử lại sau: ${response.data.data.message}`,
                    type: 'warning'
              });
        });
    },

    // tặng quà thành công

    openHomeWork(item) {
      this.dialog.title = 'Bài tập về nhà: Buổi ' + `${item.lesson}`;
      this.dialog.lesson = item;
      this.dialogHomework = true;
      this.checkClose = false;
    },

    openHomeWorkExam({homeworks, student, lesson, typeShow, learning_schedule_id}) {
      this.dialogHomework = false;
      this.dialog.title = 'Xem/Chấm điểm bài tập về nhà buổi số ' + lesson;
      this.dialog.student = student;
      this.dialog.data = homeworks;
      this.dialog.currentLesson = lesson;
      this.dialog.typeShow = typeShow;
      this.dialog.learning_schedule_id = learning_schedule_id;
      this.dialogHomeworkExam = true;
    },

    openExam() {
      this.dialog.title = 'Kiểm tra'
      this.dialogExam = true;
    },

    openExamResult(item) {
      let payload = {
        lesson: item.lesson,
        classroom_id: this.$route.params.id
      }
      let date = item.date_only ? item.date_only.split("-").reverse().join("-") : '';
      this.dialog.title = 'Kết quả buổi kiểm tra: ' + `Ngày ${date}`;
      this.$store.dispatch(RESULT_EXAM, payload).then((res) => {
        this.result_exams = res.data;
      }).catch(() => {
        this.result_exams = [];
      })
      this.dialogExamResult = true;
      this.dialog.data = item;
    },

    openPhuDao() {
      this.dialog.title = 'Tạo buổi phụ đạo'
      this.dialogPhuDao = true;
      this.action = 'Open PhuDao';
    },

    openEditPhuDao(id) {
      this.dialog.title = 'Sửa buổi phụ đạo'
      this.dialog.id = id;
      this.dialogEditPhuDao = true;
    },

    querySever(customProperties) {
      this.loading = true;
      let params = this.mergeParams(customProperties);
      this.$store.dispatch(CLASSROOM_SCHEDULE, params)
          .then((response) => {
            if (response.error) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              if (response.data.length > 0) {
                let listLessonTest = [];
                this.class_info = response.data;
                listLessonTest = response.data.filter(item => item.type === 'test').map(lesson => lesson.lesson)
                this.listLesson = response.data;
                this.currentLesson = this.listLesson.findLast(item => this.isBeforeDateTime(item.date)).lesson;
                localStorage.setItem("listTest", JSON.stringify(listLessonTest))
                localStorage.setItem("listLesson", JSON.stringify(response.data))
              }
            }
            this.loading = false;
          })
    },
    isBeforeDateTime(date){
      let time = new moment(date);
      return time < new moment();
    },

    mergeParams(customProperties) {
      let params = {};
      if (this.$route.params.id) {
        params = _.merge(params, {classroom_id: this.$route.params.id})
      }
      params = _.merge(params, customProperties);
      return params;
    },

    tutoringSuccess() {
      this.dialogPhuDao = false;
      this.action = '';
      this.querySever();
    },

    martSuccess() {
      this.dialogHomeworkExam = false;
      this.querySever();
    },
    async openOffLesson(item) {
      this.index = item.index;
      this.item = item.item
      this.dialogRequestOffLesson = true;
      let payload = {}
      payload.lesson = this.item.lesson
      payload.class_id = this.$route.params.id;
      this.next_lesson_date = this.class_info[this.index + 1] ? this.class_info[this.index + 1].date : (await this.$store.dispatch(GET_NEXT_DATE_LESSON, payload)).data
    },
    submitOffClass() {
      let payload = {}
      payload = {
        reason_off:this.reason_off,
        lesson:this.item.lesson,
        class_id : this.$route.params.id,
        old_schedule : this.item.date_only,
        new_schedule : this.next_lesson_date,
        date_of_week: this.item.date_of_week,
        start_time: this.item.start_time,
        end_time: this.item.end_time,
        type : 2,
        status : 0
      }
      this.$store.dispatch(REQUEST_OFF_LESSON, payload).then((res) => {
        this.$message({type: 'success', message: `${res.message}`});
        this.dialogRequestOffLesson = false;
        this.reason_off = '';
        this.querySever();
      }).catch(() => {
        this.result_exams = [];
      })
    },
    cancelOffLessson(item) {
      this.$confirm('Hủy buổi nghỉ học ?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        let payload = {};
        payload.lesson = item.item.lesson
        payload.class_id = this.$route.params.id
        this.class_info[item.index].check_off = false;
        this.$store.dispatch(CANCEL_REQUEST_OFF_LESSON, payload)
        this.$message({
          type: 'success',
          message: 'Hủy yêu cầu nghỉ học thành công.'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Không hủy yêu cầu nghỉ học.'
        });
      });
    },

    dialogClose() {
      this.dialogCloseHomework = true;
      this.checkClose = true;
    },

    closePopup() {
      this.dialogCloseHomework = false;
      this.dialogHomework = false;
    },
    show(value) {
      this.showHistory = true;

      this.$store.dispatch(GET_TIME_OPEN_SLIDE, {
        classroom_id: this.$route.params.id,
        lesson: this.item.lesson
      }).then((res) => {
        if (!res.error) {
          this.dataTimeOpenSlide = res.data;
        }
      });
    },
    checkLastSubnission(lesson) {
        if (this.checkClose) {
            return;
        }
        this.$store.dispatch(CHECK_LAST_SUBMISSION, {
            classroom_id: this.$route.params.id,
            lesson: lesson.lesson,
            type: 'homework'
        }).then((res) => {
            if (res.data && this.dialogHomeworkExam == false) {
                this.dialogHomework = true;
                this.dialogCloseHomework = true;
                this.checkClose = true;
            }
        })
    },

    openDialogNofication() {
      let id_class = this.$router.currentRoute.params.id;
      this.$store
        .dispatch(GET_INFO_DATA_UPLOAD_CLASS, {
          id_class,
        })
        .then((res) => {
            this.infoDataUpload = res.data;
            if(res.data.is_error_notification){
              this.DialogNotification.end_timeNotification = res?.data?.end_time;
              this.DialogNotification.startTimeNotification = res?.data?.start_time;
              this.DialogNotification.deathline_nofi = res?.data?.deadline_notification;
              this.DialogNotification.owe_file_upload = res?.data?.more_file;
              this.dialogNoficationDataClass = true;
            }
        })
        .catch(() => {});
    },


    switchUploadDataClass() {
        let id_class = this.$router.currentRoute.params.id;
        this.$router.push({ name: 'upload', params: { id: id_class }});
    },
    handleData(data) {
      let filteredArray = this.attendance_lesson.filter(obj2 => {
        let matchingObj = data.find(obj1 => obj1.course_student_id === obj2.id && obj1.val === 2);
        return matchingObj !== undefined;
      });
      this.studentRegister = filteredArray;
      if (!Object.values(this.studentRegister).length) {
        this.submint();
        return;
      }
      this.dialogReason = true;
    },
    submint() {
      let data = this.getPayloadAttendance();
      this.loadingSubmit = true;

      let filteredArray = data.data.filter(obj2 => {
        let matchingObj = this.studentRegister.find(obj1 => obj1.id === obj2.course_student_id);
        obj2.reason = matchingObj ? matchingObj.reason : '';
        return obj2;
      });

        let check = this.studentRegister.filter((d) => d.reason == '' || d.reason == null);

        if (Object.values(check).length)
        {
            this.$message({
                message: "Điểm danh thất bại !",
                type: 'error'
            });
            return;
        }


      let payload = {
          startTimeLesson: this.item.date_only,
          startTime: this.item.start_time,
          classroom_id: this.$route.params.id,
          lesson: this.item.lesson,
          greaterComingLesson: this.item.greaterComingLesson,
          type: this.item.type,
          data: filteredArray,
          is_show_phase:this.checkShowPhase
      };

      this.$store.dispatch(ACTION_ATTENDANCE, payload).then((res) => {
        if (!res.error) {
          this.$message({
            message: 'Điểm danh thành công',
            type: 'success'
          });
          this.closeDialog();
          this.dialogReason = false;
          this.loadingSubmit = false;
        } else {
          this.$message.error('Oops, Điểm danh thất bại.');
        }
        this.dialog.disable = false;
      }).catch((res) => {
        this.$message({
          message: res.data.message,
          type: 'error'
        });
        this.closeDialog();
        this.dialog.disable = false;
      });

    }
  },
}
</script>
<style scoped>
.gift .el-dialog {
    width: 60%;
}
@media screen and (max-width: 425.5px) {
  .gift .el-dialog {
    width: 80%;
}
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .gift .el-dialog {
    width: 80%;
}
}

.support-class {
  position: fixed;
  right: 1.5rem;
  bottom: 4.3125rem;
  z-index: 931;
}

.support-class-noti {
    display: flex;
    width: 350px;
    min-height: 200px;
    padding: 14px 26px 14px 13px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #EBEEF5;
    position: fixed;
    background-color: #FFF;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    transition: opacity .3s, transform .3s, left .3s, right .3s, top .4s, bottom .3s;
    overflow: hidden;
    top: 310px;
    z-index: 2000;
    right: 50px;
    opacity: 1;
    transform: translateY(0);
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .3s, transform .3s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateY(20px);
}
.noti-container {
    margin-left: 13px;
    margin-right: 8px;
}

.see-now {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
